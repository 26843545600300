/**
 * placeholders
 **/
export const COUNT_PLACEHOLDER = "{count}";
export const NAME_PLACEHOLDER = "{name}";
export const ORIGIN_PLACEHOLDER = "{origin}";
export const TARGET_PLACEHOLDER = "{target}";

const messages = Object.freeze({
  "+addNewSessionType": "+ Add new session type",
  "+createSchedule": "+ Create Schedule",
  "11Session": "1:1 session",
  "11Sessions": "1:1 sessions",
  aPlaceForEverything: "A Place for Everything",
  aboutUs: "About Us",
  acceptAndContinue: "Accept & continue",
  acceptableUsePolicy: "Acceptable Use Policy",
  add: "Add",
  adminNote: "Admin Note",
  adminNoteTooltip:
    "An administrative note is any non-clinical note related to the session. For example, if there’s a reminder that your client mentioned they may be late to your next appointment, you may want to mark that as an administrative note. You can access clinical notes by clicking here.",
  addNew: "+ Add New",
  addANewSessionType: "Create Session Type",
  addAPayment:
    "Add a payment method before setting up your one on one sessions.",
  addAsSession: "Add as session",
  addAddendum: "Add Addendum",
  addAddress: "Add Address",
  addCancellationPolicy: "Add cancellation policy",
  addClient: "Add client",
  addDescriptionOfThisSession: "Add description of this session (optional)",
  addEmailAddress: "Add Email Address",
  addExistingClientOrContact: "Add existing client or contact",
  addPhoneNumber: "Add Phone Number",
  addProgressNote: "+ Add Progress Note",
  addSessionTypes: "Add session types",
  addTimeslot: "Add timeslot",
  addToCalendar: "Add to Calendar",
  addTreatmentPlan: "Add Treatment Plan",
  addendum: "Addendum",
  addonServices: "Add-on Services",
  address: "Address",
  adult: "Adult",
  advanced: "Advanced",
  after: "After",
  aimForAffordability: "Aim for Affordability",
  aiProgressNoteGenerator: "AI Progress Note Generator",
  all: "All",
  applyingEffect: "Applying Effect..",
  areYou: "Are you sure you want to exit?",
  areYouSure: "Are you sure?",
  areYouSureYourNewSessionType:
    "Are you sure? Your new session type won't be saved if you cancel.",
  applyToAll: "Apply to all",
  allAttendeesWillBeRefunded: "All attendees will be refunded.",
  allChangesSavedAsDraft: "All changes saved as draft",
  allDay: "All Day",
  allowEmails: "Allow emails",
  allowTextMessages: "Allow text messages",
  allowVoiceMessages: "Allow voice messages",
  alreadySigned: "Already signed",
  alreadySubmitted: "Already submitted",
  applyBackgroundAndEffects: "Apply background & effects",
  attach: "Attach",
  attachments: "Attachments",
  attachform: "Attach form",
  attachForm: "Attach Form",
  attachIntakeForm: "Attach intake form",
  attachNote: "Attach Note",
  attendees: "Attendees",
  audioRecordingBackupWarning:
    "Audio Recorder is still in Beta. We recommend taking backup notes just in case",
  availabilitySaved: "Availability saved",
  avg: "Avg.",
  basic: "Basic",
  back: "Back",
  backToNotesAndForms: "Back to Notes & Forms",
  backToPatients: "Back to Patients",
  "bi-weekly": "Bi-Weekly",
  billing: "Billing & Insurance",
  billingType: "Billing Type",
  behavior: "Behavior",
  behavioralDefinitions: "Behavioral Definitions",
  blockDetails: "Block Details",
  blockTitle: "Block Title",
  blockedTime: "Blocked time",
  blocks: "Blocks",
  blog: "Blog",
  blurYourBackground: "Blur your background",
  book: "Book",
  bookASession: "Book a session",
  calendar: "Calendar",
  calendarView: "Calendar view",
  cancel: "Cancel",
  cancelSession: "Cancel session",
  cancelDownload: "Cancel download",
  cancelDownloadAsk: "Cancel download?",
  cancelUpload: "Cancel upload",
  cancelUploadAsk: "Cancel upload?",
  cancellationFee: "Cancellation fee",
  cancellationNotice: "Cancellation Limit",
  cancellationPolicy: "Cancellation Policy",
  cancelled: "cancelled",
  cancelledByPatient: "Cancelled by patient",
  cancelledSessions: "Canceled sessions",
  cancelModalTitle: "Are you sure you want to cancel this session?",
  cancelTheMove: "Cancel the move",
  caseStudies: "Case Studies",
  chat: "Chat",
  choose: "Choose",
  chooseAFormTemplate: "Choose a form template to attach to this message",
  chooseANoteFromBelow: "Choose a note from below.",
  chooseANoteTemplate: "Choose a note template to attach to this session.",
  chooseANoteTemplateAndWarning:
    "Choose a note template to attach to this session. Current note will be discarded and you cannot undo this action.",
  chooseATreatmentPlan: "Choose a treatment plan to create.",
  city: "City",
  click: "Click",
  clickCreateNew: "Click 'Create New'",
  client: "Client",
  clientInfo: "Client Info",
  clientInformation: "Client Information",
  clientService: "Client Service",
  close: "Close",
  closeNote: "Close Note",
  completeAndSave: "Complete and save",
  completed: "completed",
  confirm: "Confirm",
  confirmAndShare: "Confirm & Share",
  confirmPassword: "Confirm password",
  syncWithGoogleCalendar: "Sync with Google Calendar",
  contact: "Contact",
  contactInformation: "Contact Information",
  continue: "Continue",
  continueDownload: "Continue download",
  continueNote: "Continue Note",
  continueUpload: "Continue upload",
  couple: "Couple",
  cptCode: "CPT/Service Code",
  create: "Create",
  createAFolderIn: "Create a folder in",
  createAFirstSessionType: "Create a First Session Type",
  createANewPatient: "+ Create a new patient",
  createAPassword: "Create a password",
  createASessionType: "Create a Session Type",
  createAnAutomatedWelcomeMessage:
    "Create an automated welcome message to greet the patients that book a 1:1 session with you. You can also attach a custom form you will create later to the message (i.e. Client Intake form).",
  createAtLeast1Session: "Create at least 1 session!",
  createFolder: "Create folder",
  createInvoice: "+ Create Invoice",
  create11Session: "Create 1:1 session",
  createSchedule: "Create Schedule",
  createSessionOnUnavailableDate: "Create session on unavailable date?",
  createSuperbill: "Create Superbill",
  creatingInvoice: "Creating Invoice...",
  crisisTextLine: "Crisis Text Line",
  custom: "Custom",
  customizeWelcomeMessage: "Customize Welcome Message",
  dashboard: "Dashboard",
  dataAnalysis: "Data Analysis",
  dataTransfer: "Data Transfer",
  dateAdded: "Date added",
  dateOfBirth: "Date of Birth",
  dateRange: "Date Range",
  dateSent: "Date Sent",
  day: "Day",
  days: "days",
  defaultSessionTypes: "Default session types",
  defaultSessionTypesDetails:
    "Here's some basic session types for you to get started. Select any of the ones listed below and customize price, length, etc.",
  delete: "Delete",
  deleteGoal: "Delete goal?",
  deleteNote: "Delete Note",
  deletePhoto: "Delete photo",
  deleteSchedule: "Delete Schedule?",
  deleteSessionType: "Delete session type?",
  deleteSuperbill: "Delete Superbill",
  diagnosis: "Diagnosis",
  diagnosisCode: "Diagnosis Code",
  ditchThePhoneTag:
    "Ditch the phone tag (and phone calls entirely) with private & secure DMs that go straight to your provider. No more wait times, just replies.",
  discard: "Discard",
  done: "Done",
  dontCancel: "Don't cancel",
  download: "Download",
  downloadingCancelled: "Downloading cancelled",
  downloadingFiles: "Downloading files...",
  downloadQueue: "Download Queue",
  downloadReceipt: "Download Receipt",
  downloadSuperbill: "Download Superbill",
  doYouWantToDeleteThisAttachment: "Do you want to delete this attachment?",
  doYouWantToDeleteThisSection:
    "Do you want to delete this section? Your answer will not be saved.",
  doYouWantToFill: "Do you want to fill in the note right now?",
  draftIsBeingSaved:
    "Draft is being saved. You will lose your data when you leave this page when the data is being saved.",
  drafted: "Drafted ",
  draftSaved: "Draft saved ",
  dragAndDropYourFiles: `Drag & Drop your file(s) here.
  (File must be in .zip format)`,
  dropHere: "Drop here to instantly upload your files.",
  dropYourFileHere: "Drop your file here.",
  duplicate: "Duplicate",
  easyCommunication: "Easy Communication",
  edit: "Edit",
  editAvailability: "Edit availability",
  editSession: "Edit Session",
  editSessionType: "Edit Session Type",
  editSessionConfirmModalBody:
    "If you edit the session type, the changes will be reflected on the new sessions created after the edit. All previously booked sessions in the calendar will not be affected.",
  editTreatmentPlan: "Edit Treatment Plan",
  editUnavailability: "Edit unavailability",
  email: "Email",
  emailAddressDefault: "Email Address (default)",
  emergencyContact: "Emergency Contact",
  ends: "Ends",
  endDate: "End Date",
  enterAmount: "Enter amount",
  enterDescription: "Enter description",
  enterEmailAddress: "Enter Email Address",
  enterExternalUrl: "Enter External URL",
  enterLegalFirstName: "Enter Legal First Name",
  enterLegalLastName: "Enter Legal Last Name",
  enterMiddleName: "Enter Middle Name",
  enterNote: "Enter Note",
  enterPassword: "Enter password",
  enterPhoneNumber: "Enter Phone Number",
  enterPreferredName: "Enter Preferred Name",
  enterSessionName: "Enter session name",
  enterStreetAddress: "Enter Street Address",
  enterTitleOfTheBlock: "Enter Title of the block",
  enterYourDescription: "Enter your description",
  enterYourFirstName: "Enter your first name",
  enterYourLastName: "Enter your last name",
  every: "Every",
  every2Weeks: "Every 2 weeks",
  everyChangeYouMake: "Every change you make is automatically saved as draft.",
  everyProfessional:
    "Every professional at Orchid has been personally vetted & rigorously approved by our team — and we have very high standards.",
  exit: "Exit",
  existingNow:
    "Exiting now will not post your sessions to our website. We can save your progress you can come back to it later.",
  externalTelehealthLink: "External Telehealth Link",
  failed: "failed",
  failedToSaveChangesRetrying: "Failed to save changes. Retrying...",
  faq: "FAQ",
  fileUpload: "File upload",
  folderUpload: "Folder upload",
  forDays: `for ${COUNT_PLACEHOLDER} days`,
  files: "Files",
  filesZipped: "files zipped",
  fillinNote: "Fill in Note",
  filter: "Filter",
  filterBy: "Filter by",
  findASupportGroup: "Find a Support Group",
  finish: "Finish",
  finishSettingUpYourSchedule: "Finish setting up your schedule",
  firstGoal: "First Goal",
  firstName: "First name",
  flatFee: "Flat fee",
  folders: "Folders",
  followUpSession: "Follow up session",
  followUs: "Follow Us",
  forClinicians: "For Clinicians & Patients",
  forProfessionals: "For Professionals",
  forms: "Forms & Portal",
  formTitle: "Form Title",
  free: "Free",
  freeIntroduction: "Free introduction",
  freeOfCharge: "Free of charge",
  freeResources: "Free Resources",
  from: "From",
  fromFillingOutYourIntake:
    "From filling out your intake to paying your provider, everything you need to thrive is included in one easy peasy platform.",
  gad7: "GAD-7",
  gad7Anxiety: "GAD-7 (Anxiety)",
  gcalEventInfo:
    "To edit or remove this event, please make changes in the original calendar and Orchid will automatically update it.",
  gender: "Gender",
  getAllTheCommunity:
    "Get all the community, camaraderie, and strength of group sessions — from the comfort of your favorite couch. (Bonus points if there's a  fuzzy blanket.)",
  getStarted: "Get started",
  getSupport: "Get Support",
  getTheFullStory: "Get the Full Story",
  getYourFreeAccount: "Get your free account",
  goBack: "Go back",
  goToNote: "Go to Note",
  goal: "Goal",
  googleCalendar: "Google Calendar",
  googleEvent: "Google event",
  gotIt: "Got it",
  group: "Group",
  groupSession: "Group session",
  groupSessions: "Group sessions",
  groupSessionSeries: "Group session series",
  hasBeenMovedFrom: `${NAME_PLACEHOLDER} has been moved from ${ORIGIN_PLACEHOLDER} to ${TARGET_PLACEHOLDER}`,
  haveBeenMovedFrom: `${COUNT_PLACEHOLDER} items have been moved from ${ORIGIN_PLACEHOLDER} to ${TARGET_PLACEHOLDER}`,
  historically1: "Historically, ",
  historically2: "80%",
  historically3: `of folks in the U.S. who want mental healthcare never actually get it. (We were shocked, too.)

Historically, therapists and psychiatrists have to spend more time with their business software & computers than with you. 
(The opposite of how it works for our crew.) 

And historically, only 13% of mental healthcare professionals use techniques backed by evidence as the basis of their practice. (100% of our providers do.)
`,
  home: "Home",
  hoursShort: "h",
  iAmOver181: "I am over 18 and agree to the ",
  iAmOver183: " and ",
  iAmOver184: "Privacy Policy",
  iCancelledTheSession: "I cancelled the session",
  iHaveRead: "I have read and agree to the ",
  iMissedTheSession: "I missed the session",
  icdDiagnosisCode: "ICD-10 Diagnosis code",
  importData: "Import Data",
  importFrom: "Import From",
  independentContractorAgreement: "Independent Contractor Agreement",
  individualSessions: "Individual Sessions",
  infiniteFlexibility: "Infinite Flexibility",
  instantScheduling: "Instant Scheduling",
  insurance: "Insurance",
  insuranceDetails: "Insurance Details",
  intervention: "Intervention",
  invitepatient: "Invite patient",
  invoice: "Invoice",
  invoicedSession: "Invoiced Session",
  inOrderToActivate:
    "In order to activate your sessions and see them live on the platform, you need to connect with Stripe* account. You can always skip this step now and setup at a later time.",
  ifAPatientCancelsWithin:
    "If a patient cancels within the hours selected, they will incur a cancellation fee that you set up.",
  ifThisIsAnError: "If this is an error, please contact us at",
  ifYouCancelTreatment:
    "If you cancel, the treatment plan will be removed and your input won't be saved.",
  ifYouClose: "If you close, your edit will not be saved.",
  ifYouCloseWithoutSaving:
    "If you close without saving, the note won't be saved. Do you want to close this note?",
  ifYouDeleteAddendum: "If you delete, the addendum will not be saved.",
  ifYouDeleteDiagnosis:
    "If you delete this diagnosis, you'll also remove the treatment plan associated with it.",
  ifYouDeleteTreatment:
    "If you delete, it would delete the data permanently. Do you want to delete the Treatment Plan?",
  ifYouHaveAnEmergency1:
    "If you have an emergency and need immediate help, call ",
  ifYouHaveAnEmergency2: "911",
  ifYouHaveAnEmergency3: " or go to the nearest emergency room.",
  ifYouRemoveGoal:
    "If you remove this goal, you'll also remove the objectives and interventions associated with it.",
  ifYouWantToConfigure1: `If you want to configure the auto-pilot setting of the assessments, go to `,
  ifYouWantToConfigure2: " setting from Global Setting tab.",
  ifYouWantToSendThisClientEmailReminders: `If you want to send this client email reminders or billing documents, and to grant them Client Portal access, add their email address.
Do not add a guardian’s email in this field. If another person is responsible for this client, create contact tab and add their information.`,
  ifYouWantToSendThisClientTextReminders:
    "If you want to send this client text reminders, add their phone number(s).",
  ifYouWantToSendThisContactEmailReminders: `If you want to send this contact email reminders or billing documents, and to grant them Client Portal access, add their email address.
Do not add a patient’s email in this field.`,
  item: "item",
  items: "items",
  joinAsAProvider: "Join as a Provider",
  joinNow: "Join Now",
  joinOnZoom: "Join on Zoom",
  keepEditing: "Keep editing",
  keptSeparate: "(Kept separate from the client record)",
  learnMore: "Learn more",
  last: "Last",
  last30Days: "Last 30 Days",
  lastMonth: "Last Month",
  lastName: "Last name",
  legalFirstName: "Legal First Name",
  legalLastName: "Legal Last Name",
  length: "Length",
  lengthOfSession: "Length of session",
  less: "less",
  letsPutTheCareBack: "Let's put the care back in mental healthcare.",
  listView: "List view",
  listYourServices: "List Your Services",
  load: "Load",
  loadPreviousNote: "Load Previous Note",
  loading: "Loading...",
  loadingPreviousNote:
    "If you load a previous note, any edits made to the current template will be deleted.",
  loadingSuperbill: "Loading Superbill...",
  location: "Location",
  login: "Login",
  logIn: "Log in",
  lookAtYourProvider:
    "Look at your provider's calendar, choose your favorite time, and then show up to your session. That's it. (You can even reschedule via text.)",
  looksLike: "Looks like you have not submitted a blog. Submit a blog today!",
  markedAsAvailable: "Marked as available",
  makeSureProviderAndPracticeInformation:
    "Make sure Provider and Practice information are all filled in before ",
  measurements: "Measurements",
  measurementBasedCare: "Measurement Based Care",
  mentalHealthcareFrom:
    "Mental healthcare from anywhere that meets you where you're at.",
  maxFileSize: "Max. file size: ## MB",
  messages: "Messages",
  middleName: "Middle Name",
  mild: "Mild",
  mildAnxiety: "Mild Anxiety",
  minutesShort: "min",
  minimalAnxiety: "Minimal Anxiety",
  minor: "Minor",
  minute: "minute",
  moderate: "Moderate",
  moderateAnxiety: "Moderate Anxiety",
  moderatelySevere: "Moderately Severe",
  month: "Month",
  monthly: "Monthly",
  more: "more",
  moveHere: "Move here",
  moveTo: "Move to",
  myAvailability: "My Availability",
  myBlogs: "My blogs",
  mySchedule: "My Schedule",
  mySessionTypes: "My Session Types",
  name: "Name",
  negative: "Negative",
  newSchedule: "New Schedule",
  next: "Next",
  none: "None",
  nonminimal: "Non-minimal",
  notes: "Sessions & Notes",
  noteTemplateIsAttached: "Note template is attached to this sessions!",
  noteType: "Note Type",
  nothingBookedForTheDay: "Nothing booked for the day!",
  noEndDate: "No End Date",
  noNote: "No Note",
  noNoteAttachments: "No note attachments!",
  noSessionTypes: "No Session Types",
  noTitle: "No title",
  notNow: "Not now",
  noticeOfDataIntegrity: "Notice of Data Integrity",
  noticeOfDataIntegrityBody:
    "As a user of Orchid, I acknowledge that I am responsible for all information I input into Orchid, including changes made to the submitted notes. I understand and agree that I am bound by various laws and regulations, including but not limited to HIPAA and professional ethics, which require that I preserve the availability, accuracy, integrity, and confidentiality of protected health information. I also understand and agree that all of my activity within Orchid is automatically logged, including addendum and changes to this note, and that my activity may be audited by account owners, account administrators, regulators, or others.",
  noCancel: "No, Cancel",
  noCancellationPolicy: "No cancellation policy",
  noCreateGoalNow: "No, create goal now",
  noCreateObjectiveNow: "No, create objective now",
  noDataimports: "No data imports yet.",
  noPreviousNote: "No previous note for this patient.",
  noKeepEditing: "No, keep editing",
  noRecordFound: "No record found",
  noResultsFound: "No results found",
  noClientFound: "No client found",
  noDont: "No, Dont",
  noSchedule: "No schedule",
  noSessionTypeAvailable: "No session type available",
  // expected to be multiline, do not change to one line string
  yourAvailabilityWillBePosted: `Your availability will be posted on
your profile page on`,
  findAProfessional: "Find a Professional",
  findProfessional: "Find Professional",
  objective: "Objective",
  of: "of",
  off: "Off",
  ok: "Ok",
  on: "On",
  onDate: "On Date",
  oneOnOne: "One on one",
  oneWaySync: "One-Way Sync",
  oneOnOneSession: "One-on-one session",
  oneOnOneSessions: "One-on-one sessions",
  oops: "Oops...Something went wrong.",
  oopsConflictingSession: "Oops...There is a conflicting session!",
  or: "OR",
  orchidStorage: "Orchid Storage",
  orchidTelehealth: "Orchid Telehealth",
  orchidVerified: "Orchid Verified",
  organizer: "Organizer",
  others: "Others",
  outcomeMeasures: "Outcome Measures",
  overview: "Overview",
  participants: "Participants",
  password: "Password",
  pastDue: "PAST DUE",
  pastSessions: "Past sessions",
  partner: "Partner",
  partnerWithOrchid: "Partner with Orchid",
  patient: "Patient",
  patientInformation: "Patient Information",
  patientName: "Patient Name",
  patientSubmitted: "Patient submitted",
  patients: "Patients",
  payment: "Payment",
  paymentMethod: "Payment Method",
  pendingPaymentConfirmationWarning:
    "Pending Payment Confirmation. This session is a Pre-authorized session that requires client’s approval to confirm the session.",
  permanentFolders: "Permanent Folders",
  phone: "Phone",
  phoneNumberDefault: "Phone Number (default)",
  phq9: "PHQ-9",
  phq9Depression: "PHQ-9 (Depression)",
  pickYourFavoritePayment:
    "Pick your favorite payment method, like FSA, HSA, credit cards, or cash — and insurance benefits are coming soon!",
  pleaseEnterAtLeastOneCPT:
    "Please enter at least one CPT code if you have any ICD-10 code.",
  pleaseEnterThePassword: "Please enter the password for “{name}”",
  pleaseUploadYourPatient:
    "Please upload your patient .zip file here. Once file is uploaded, we will begin importing your data to Orchid. You will be notified via email when your file is successfully imported.",
  points: "points",
  positive: "Positive",
  postYourBlog: "Post your blog here",
  practiceServices: "Practice Services",
  preauthorizedCc: "Pre-authorized CC",
  preauthorizedCcTooltipLine1:
    "Orchid only holds the payment until the session is confirmed by your patient.",
  preauthorizedCcTooltipLine2:
    "Send a reminder to your patient to confirm the session invite.",
  preferredName: "Preferred Name",
  presentingProblem: "Presenting Problem",
  press: "Press",
  preview: "Preview",
  previous: "Previous",
  price: "Price",
  primaryClinician: "Primary Clinician",
  print: "Print",
  privacy: "Privacy",
  professionals: "Professionals",
  profile: "Profile",
  psGreatJob: `P.S. Great job lookin' into help for your mental health.
  Because you deserve brighter days.`,
  psychotherapyNote: "Psychotherapy Note",
  read: "Read",
  readyToMatch:
    "Ready to match with a provider you trust, like, and look forward to talking to?",
  readyToReschedule: "Ready to reschedule this session?",
  readyToUpdateYourAvailability: "Ready to update your availability?",
  receipt: "Receipt",
  receiptFor: "Receipt for",
  recentImports: "Recent Imports",
  regularSession: "Regular session",
  relationshipToPatient: "Relationship to patient",
  reminder: "Reminder",
  remindViaChat: "Remind via Chat",
  removeTimeslot: "Remove timeslot",
  rename: "Rename",
  repeat: "Repeat",
  repeatOn: "Repeat on",
  requiredForInsuranceBilling:
    "Required for insurance billing—please use the client’s address they have on file with their insurance provider.",
  reschedule: "Reschedule",
  save: "Save",
  saved: "Saved",
  saveAsDraft: "Save as draft",
  saveAndAdd: "Save and add",
  saveAndClose: "Save & close",
  saveAndExit: "Save and exit",
  saveChanges: "Save changes",
  savedJustNow: "Saved just now",
  savingDraft: "Saving Draft...",
  savingsCalculator: "Savings Calculator",
  sayHey1: "Say ",
  sayHey2: "hey!",
  sayHey3: " to some of our incredible specialists.",
  schedule: "SCHEDULE",
  scheduleASession: "Schedule a New Session",
  scheduleAnIndividualSession: "Schedule an Individual Session",
  score: "Score",
  search: "Search",
  searchAndSelect:
    "Search and select a patient from existing patients and contacts on file",
  searchByICD: "Search by ICD-10 Diagnosis code or description",
  searchByProcedure: "Search by procedure code or description",
  searchExistingPatientsAndContacts: "Search existing Patients and Contacts",
  searchForPeople: "Search for mental health professionals and sessions",
  searchPatient: "Search patient",
  searchPatientName: "Search patient name",
  security: "Security",
  secondGoal: "Second Goal",
  sex: "Sex",
  select: "Select",
  selectADate: "Select a date",
  selectAMeetingType: "Select a meeting type",
  selectASessionType: "Select a session type",
  selectAStartDate: "Select a start date.",
  selectAnEndDate: "Select an end date.",
  selectAnOption: "Select an option",
  selectAvailableDays: "Select available days",
  selectEndDate: "Select End Date",
  selectEndTime: "Select end time.",
  selectGender: "Select Gender",
  selectSex: "Select Sex",
  selectStartDate: "Select Start Date",
  selectStartTime: "Select start time.",
  selectTelehealtheMeetingType: "Meeting type",
  selectTheSchedule: "Select the schedule to edit availability.",
  selected: "selected",
  selfPay: "Self Pay",
  send: "Send",
  sendAWelcomeMessage: "Send a welcome message",
  sendAssessments: "Send assessments on auto-pilot to ",
  sendMessageToClient: "Send message to client",
  sendChat: "Send Chat",
  sendEmailNotifications: "Send email notifications related to the client",
  sendEmailReminders: "Send Email Reminders 24 hours before sessions",
  sendNotificationsOf:
    "Send Notifications of new, cancelled, and rescheduled sessions",
  sentDate: "Sent Date",
  sessionStatus: "Session Status",
  setAsAvailable: "Set as available",
  setAsUnavailable: "Set as unavailable",
  setCancellationPolicy: "Set Cancellation Policy",
  setReminderToReviewPlan: "Set reminder to review plan",
  setSessionToPrivate: "Set session to private",
  setToDefault: "Set to default",
  setUpAvailability: "Set up Availability",
  setUpPayment: "Set up payment",
  setUpPaymentMethod: "Set up payment method",
  setUpWeeklyHours: "Set Up Weekly Hours",
  setUpYourFirstCalendarAvailability: "Set up your first Calendar Availability",
  setUpYourPaymentMethod:
    "Set up your payment method to make your availability public.",
  setUpYourSchedule: "Set up your schedule",
  setUpYourWeeklyAvailableHours:
    "Set up your weekly available hours. Your patients will be able to book sessions within the hours you set up. You can add and edit more schedules later.",
  setup: "Setup",
  setupPaymentMethod: "Setup payment method",
  setupPaymentMethodInfo:
    "In order to activate your sessions and see them live on the platform, setup your preferred payment menthod. You can always skip this step now and setup at a later time.",
  setupYourPaymentMethod: "setup your payment method",
  sessions: "Session(s)",
  sessionRatePercentage: "% of Session Rate",
  sessionDate: "Session Date",
  sessionDescription: "Session Description",
  sessionDetails: "Session Details",
  sessionName: "Session Name",
  sessionType: "Session Type",
  // TODO: decide a pattern whether the second word should be uppercase
  sessiondate: "Session date",
  sessiontype: "Session type",
  severe: "Severe",
  severeAnxiety: "Severe Anxiety",
  share: "Share",
  signAndSubmit: "Sign & Submit",
  signInWithGoogle: "Connect with Google",
  signOut: "Sign out",
  sinceBaseline: "since baseline",
  sinceLastAssessment: "since last assessment",
  size: "Size",
  sometimesYourPatients:
    "Sometimes your patients have to cancel the sessions. Set a cancellation policy to find a balance between your flexibility and your schedule.",
  speakers: "Speakers",
  startDate: "Start Date",
  stateProvince: "State/Province",
  status: "Status",
  step: "Step",
  street: "Street",
  skip: "Skip",
  skipAndDoLater: "Skip and do later",
  skipToTheEnd: "Skip to the end?",
  submit: "Submit",
  submitABlog: "Submit a blog",
  submitted: "Submitted",
  success: "Success",
  suicidePreventionLifeline: "Suicide Prevention Lifeline",
  superbillFor: "Superbill for",
  superbillIsShared: "Superbill is shared to your patient.",
  superbillSharedToPatient: "Superbill shared to patient",
  supportGroups: "Support Groups",
  supportThatGoes: "Support that goes where you do.",
  switch: "Switch",
  switchToOrchid: "Switch To Orchid",
  switchNoteTemplate: "Switch Note Template",
  syncYourGoogleCalendar:
    "Syncing your Google Calendar will allow you to view your Google Calendar events on the Orchid calendar.",
  syncGoogleCalendar: "Sync Google Calendar",
  syncGoogleCalendarSuccess:
    "You have successfully synced with Google Calendar. Events from the synced calendar will appear in the schedule within a minute.",
  syncGoogleCalendarFailure:
    "Error syncing with your Google Calendar. Please try later.",
  take30Seconds: "Take 30 seconds to get your free Orchid account.",
  termsOfUse: "Terms of Use",
  textHomeTo: "Text HOME to ",
  theCurrentSuperbillAndItsInformation:
    "The current superbill and it's information will not be saved. Do you want to close the tab?",
  theCurrentSuperbillWillNotBeSaved:
    "The current superbill will not be saved and your patient will no longer be able to view current superbill. Do you want to delete?",
  theScheduleInformationWillNotBeSaved:
    "The schedule information will not be saved. Do you want to delete this schedule?",
  theSessionInformationWillNotBeSaved:
    "The session information will not be saved. Do you want to delete this session type?",
  thereIsNoAttachmentFound:
    "There is no attachment found because no notes have been signed and submitted yet.",
  thereIsConflictWithExisitngSession:
    "There is a conflict with the existing session time. Do you want to reschedule the session time?",
  theseAreTheTypesOfSessions:
    "These are the types of sessions that patients can book with you. Edit our sample session types to start with, or create your own. You can add more later.",
  thisDateIsSetAsUnavailable:
    "This date is set as unavailable. Do you still want to create a session?",
  thisFolderIsEmpty: "This folder is empty",
  thisSessionWillNotBeVisible:
    "If you set this session type to private, it will not be available for client scheduling through your public Orchid marketplace profile. Only you can schedule a private session type. These session types are ideal for when you’ve negotiated sliding scale fees to charge a client.",
  thisYear: "This Year",
  threeDays: "3 days",
  time: "Time",
  title: "Title",
  titleOfTheSessionType: "Title of the session type",
  to: "to",
  to__capitalized: "To",
  totalCharged: "Total Charged",
  totalScoreObtainedBy:
    "Total score obtained by adding score for each question:",
  today: "Today",
  toLockYourForm: " to lock your form.",
  toLockYourNote: " to lock your note.",
  toPreviewYourTemplate: " to preview your template.",
  transferYourPatientDataLine1:
    "Transfer your patient data to Orchid in 2 seconds! Upload your files and we will handle the rest.",
  transferYourPatientDataLine2Part1:
    "Unidentified files are going to be stored in ",
  transferYourPatientDataLine2Part2:
    ". Please contact us at info@orchid.health if you need any help.",
  trackableProgress: "Trackable Progress",
  treatmentPlan: "Treatment Plan",
  treatmentResponse: "Treatment Response",
  treatmentFrequency: "Treatment Frequency",
  turnOffVisualEffects: "Turn off visual effects",
  twoSimpleWays: "Two simple ways to brighter days",
  twoWaySync: "Two-Way Sync",
  typeHere: "Type here...",
  typeYourBlog: "Type your blog here",
  unableToSave: "Unable to save",
  unavailable: "Unavailable",
  unconfirmed: "Unconfirmed",
  unsyncGoogleCalendar: "Unsync Google Calendar",
  unsyncGoogleCalendarSuccess:
    "You have successfully un-synced your Google Calendar.",
  unsyncGoogleCalendarFailure:
    "Oops! We could not un-sync your Google Calendar. Please try later or contact info@orchid.health for support.",
  untitledFolder: "Untitled Folder",
  untitledSectionTitle: "Untitled Section title",
  untitledHeader: "Untitled header",
  upcomingSessions: "Upcoming sessions",
  update: "Update",
  updateAvailabilityConfirmation:
    "Clients will only be able to book sessions within your updated availability ranges. Out-of-range time slots will be hidden from the view. This update does not affect any existing sessions booked on your calendar.",
  updatePlan: "Update Plan",
  upload: "Upload",
  uploadFiles: "Upload file(s)",
  uploadFromComputer: "Upload from computer",
  uploadQueue: "Upload Queue",
  uploads: "Uploads",
  useCustomBackground: "Use custom background",
  useExistingNote: "Use existing note as a template",
  view: "View",
  viewNote: "View Note",
  viewPatientChart: "View patient chart",
  viewReceipt: "View Receipt",
  viewSuperbill: "View Superbill",
  waitTakeMeBack: "Wait, take me back",
  week: "Week",
  weekly: "Weekly",
  weAreOrchid: "We're Orchid, and we're rewriting history.",
  wePartnerWith:
    "We partner with mental health movements & sponsor causes across the country.",
  weWillHelpYou: "We will help you set up your schedule!",
  wheneverYourNeed:
    "Whenever you need a boost, book an in-person or virtual appointment with a vetted & licensed provider you trust, connect with, and actually like talking to.",
  withNoExtraHassle:
    "With no extra hassle, you can chart your moods, measure how far you've come, and celebrate your wins as they arise.",
  writeABlog:
    "Write a blog to submit to the Orchid website. We will review your submission.",
  writeAnAddendum: "Write an addendum",
  writeForUs: "Write For Us",
  year: "Year",
  yesCancel: "Yes, cancel",
  yesContinue: "Yes, continue",
  yesCreate: "Yes, create",
  yesSkipToTheEnd: "Yes, skip to the end",
  yesUpdate: "Yes, Update",
  you: "You",
  youCantSign: "You can't sign and submit now!",
  yourDataIsSecure: "Your data is secure.",
  youreEditingOneAppointmentInTheSeries:
    "You’re editing one appointment in the series",
  youWillBeAble:
    "You will be able to sign and submit the note after the session is completed. Please note that the note will be removed if the session gets cancelled.",
  youMustCreateGoal1:
    "You must create Goal 1 before you can move on to objectives and interventions. Would you like to save your work and skip to the end?",
  youMustCreateObjective1:
    "You must create Objective 1 before you can move on to interventions. Would you like to save your work and skip to the end?",
  youNeedToCreateAtLeast1SessionType:
    "You need to create at least 1 session type to continue.",
  yourAppointmentWasSuccessfullyCancelled:
    "Your appointment was successfully cancelled!",
  yourCameraIsTurnedOff:
    "Your camera is turned off. Selecting an effect will turn it on.",
  yourDownloadIsNotComplete:
    "Your download is not complete. Would you like to cancel the download?",
  yourEditsWontBeSaved:
    "Your edits won’t be saved. This action cannot be undone.",
  yourPatientWillReceive:
    "Your patient will receive a notification via registered email address to get an access to their superbill.",
  yourScheduleIsNowPublic: "Your Schedule is now public!",
  yourStripeAccountIsConnected: "Your Stripe account is connected.",
  yourUploadIsNotComplete:
    "Your upload is not complete. Would you like to cancel the upload?",
  zipcode: "Zipcode",
  zippingCancelled: "Zipping cancelled",
  zippingFiles: "Zipping files...",
} as const);

export default messages;
